import React, {useState,useEffect} from 'react';
import { View, StyleSheet, Platform, ScrollView, SafeAreaView } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlusCircle,faMinusCircle, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { Card, Button, Menu, Provider, DefaultTheme, Searchbar } from 'react-native-paper';
import  {all_users_by_role, users_by_id} from '../../services/user_api';
import { all_mandi_inventory } from '../../services/mandi_inventory';
import { useHistory } from "react-router-dom";
import {url} from '../../utils/url';
import axios from 'axios';
import { customer_manager_pool_by_manager_pool_id, customer_vendor_pool_by_customer_pool_id } from '../../services/pool';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#0cc261',
        accent: '#f1c40f',
    },
};

export default function CreateMandiOrder({ navigation }) {

    const [visible, setVisible] = useState([]);
    const [allItems, setAllItems] = useState();
    const [items, setItems] = useState([{item_name: 'Choose Item', grade: '' , unit:'', quantity:'', price:'', status: 'pending', target_price:'0', selling_price:'0'}]);
    const [visible3, setVisible3] = useState(false)
    const [buyer, setBuyer] = useState();
    const [buyer_email, setBuyerEmail] = useState("Choose Buyer");
    const [buyer_id, setBuyerId] = useState();
    const [managerPoolId, setManagerPoolId] = useState();
    const [vendorPoolId, setVendorPoolId] = useState();

    let history = useHistory();

    useEffect(() => {

        all_users_by_role("buyer")
        .then(result => {
            setBuyer(result);
        })

        all_mandi_inventory()
        .then(result => {
            setAllItems(result);
        })

        users_by_id(localStorage.getItem('loginuserid'))
        .then(result => {
            setManagerPoolId(result[0].pool_id);
        })

    }, [allItems, managerPoolId]);

    const openMenu = (index) => {
        const values = [...visible];
        values[index]=true;
        setVisible(values);
    };

    const closeMenu = (index) => {
        const values = [...visible];
        values[index]=false;
        setVisible(values);
    };

    const openMenu3 = () => setVisible3(true);
    const closeMenu3 = () => setVisible3(false);

    const ItemChange = (index, item) => {

        fetch(`${url}/retrieve_crawler_by_managerPoolId/${item.order.item_name}/${item.order.grade}/${item.order.unit}/${managerPoolId}`, {
            method: 'GET',
        })
        .then(res => res.json())
        .catch(error => console.log(error))
        .then(data => {
            const values = [...items];
            values[index].item_name = item.order.item_name;
            values[index].grade = item.order.grade;
            values[index].unit = item.order.unit;
            values[index].quantity = item.order.quantity;
            values[index].target_price = data[0].price;
            values[index].price = item.order.price;
            setItems(values);
        });

        fetch(`${url}/delete_mandi_inventory_by_id/${item._id}`, {
            method: 'GET',
        })
        .then(res => res.json())
        .catch(error => console.log(error))
        .then(data => {
            // console.log(data);
            // alert(data.message);
        });

        console.log(items);

        closeMenu(index);
    };

    const handleAddFields = () => {
        const values = [...items];
        values.push({item_name: 'Choose Item', grade: '' , unit:'', quantity:'', price:'', status: 'pending', target_price:'0', selling_price:'0'});
        setItems(values);
    };
    
    const handleRemoveFields = index => {
        const values = [...items];
        values.splice(index, 1);
        setItems(values);
    };

    function submitForm() {
        axios.post(url + '/create_mandi_order', {
                buyerId: buyer_id,
                managerId: localStorage.getItem('loginuserid'),
                items: items,
          })
          .then(function (response) {
            alert(response.data.message);
            if(response.data)
            {
                if(Platform.OS=="android"){
                    navigation.navigate('AllOrders');

                }
                else{
                    history.push('/allmandiorders');
                }
            }
            }) 
    }

    function chooseBuyer(id, email){
        setBuyerId(id);
        setBuyerEmail(email);
        closeMenu3();
    }

    return (
        <Provider theme={theme}>
            <SafeAreaView>
            <ScrollView>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Card style={styles.card}>
                    <Card.Title titleStyle={styles.title} title="Create Mandi Order"/>
                    <Card.Content>
                        <Menu
                        visible={visible3}
                        onDismiss={closeMenu3}
                        anchor={<Button style={styles.input} mode="outlined"  onPress={openMenu3}>{buyer_email} </Button>}>
                            {buyer ?
                                buyer.map((item)=>{
                                // if(item.pool_id==vendorPoolId)
                                    return (
                                        <Menu.Item title={item.nick_name} onPress={()=>chooseBuyer(item._id, item.email)} />
                                    )
                                })
                                :
                                <Menu.Item title="No Buyer Available" />
                            }
                        </Menu>
                        {items.map((it, index) => (
                            <View>
                                <Menu
                                visible={visible[index]}
                                onDismiss={()=>closeMenu(index)}
                                anchor={<Button style={{flex: 1, marginTop: '2%'}} mode="outlined" onPress={()=>openMenu(index)}>{it.item_name!="Choose Item" ? it.item_name+" ("+it.grade+")"+", Unit:"+it.unit+", Qty:"+it.quantity : "Choose Item"}</Button>}>
                                    {allItems ?
                                        allItems.map((item)=>{
                                            return (
                                                <Menu.Item title={item.order.item_name+" ("+item.order.grade+")"+", Unit:"+item.order.unit+", Qty:"+item.order.quantity} onPress={()=>ItemChange(index, item)}/>
                                            )
                                        })
                                        :
                                        <Menu.Item title="No items are available" />
                                    }
                                </Menu>
                                <View style={{flexDirection: 'row'}}>
                                    {Platform.OS=="android" ?
                                        <>
                                            <FontAwesomeIcon icon={ faMinusCircle } color={ 'red' } size={30} onPress={() => handleRemoveFields(index)}/>
                                            <FontAwesomeIcon icon={ faPlusCircle } onPress={() => handleAddFields()} color={ 'green' } size={30} />
                                        </>
                                        :
                                        <>
                                            <Button onPress={() => handleRemoveFields(index)} mode="outlined"><FontAwesomeIcon icon={ faMinusCircle } color={ 'red' } size={30}/></Button>
                                            <Button  onPress={() => handleAddFields()}  mode="outlined"><FontAwesomeIcon icon={ faPlusCircle } color={ 'green' } size={30} /></Button>
                                        </>
                                    }
                                </View>
                            </View>
                        ))}
                        <Button mode="contained" style={styles.button} onPress={()=>submitForm()} >Create Order</Button>
                    </Card.Content>
                </Card>
            </View>
            </ScrollView>
            </SafeAreaView>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        alignSelf: 'center',
        padding: '1%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                marginTop: '10%',
                marginBottom: '10%',
                width: '90%',
            },
            default: {
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
                marginTop: '4%',
                marginBottom: '4%',
                width: '75%',
            }
        })
    },
    input: {
        marginTop: '2%',
        width: '100%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                
            }
        })
    },
    title: {
        ...Platform.select({
            ios: {
                
            },
            android: {
                textAlign: 'center',
                color: 'green',
                fontFamily: 'Roboto'
            },
            default: {
                textAlign: 'center',
                color: 'green',
                fontSize: 28,
                fontFamily: 'Roboto'
            }
        })
    },
    button: {
        marginTop: '2%',
    },
    customer: {
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                flexDirection: 'row',
                justifyContent: 'space-between',
            }
        })
    }
}); 
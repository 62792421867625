import React, { useState, useEffect,useRef } from 'react';
import { View, StyleSheet, Platform, ScrollView, SafeAreaView, TouchableOpacity } from 'react-native';
import { TextInput, Card, Provider, DefaultTheme, DataTable, Title, Button } from 'react-native-paper';
import { Order_by_id } from '../../services/order_api';
import { useHistory} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFilePdf ,faFileCsv} from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf'; 
import html2canvas from 'html2canvas';
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

const theme = {
	...DefaultTheme,
	roundness: 2,
	colors: {
		...DefaultTheme.colors,
		primary: '#0cc261',
		accent: '#f1c40f',
	},
};

export default function ViewOrder(props, { route }) {

	let history = useHistory();
    const tableRef = useRef(null);

	var orderid = "";
	if (Platform.OS === "android") {
		orderid = route.params.orderId;
	}
	else {
		orderid = props.match.params.orderid;
	}

	const [order, setOrder] = useState();

	useEffect(() => {

		if (orderid) {
			Order_by_id(orderid)
				.then(result => {
					setOrder(result);
				})
		}

	}, [orderid, order]);
	const csvData1=[]
    if(order){
		csvData1.push({
			name:order[0].name,
			email:order[0].email,
			address:order[0].address+" "+order[0].landmark+" "+order[0].district+" "+order[0].state+" "+order[0].country,
			pin:order[0].postal_code
		})
	}
    const csvData2=[]
    if(order){
        var details= "Full Name :"+order[0].name+" "+"Email:"+order[0].email+" "+
        "Address:"+order[0].address+" "+"Landmark:"+order[0].landmark+" "+"District:"+order[0].district
        +" "+"State:"+order[0].state+" "+"Country:"+order[0].country+" "+"Pin Code:"+order[0].postal_code;
        if(order[0].items){
            order[0].items.map(it=>{
                csvData2.push({
                    itemName:it.itemName,
                    unit:it.itemUnit,
                    quantity:it.quantity,
                    target:it.targetPrice,
                    negotiate:it.itemNegotiatePrice,
                    details:details
                })
            })
        }
    }
	const data=[
		{
			columns: [
			  { title: "Full Name",  widthPx: 250 },
			  { title: "Email",  width: {wch: 40} },
			  { title: "Address",  widthPx: 250 },
			  { title: "Pin Code",  widthPx: 250 }
			],
			data: csvData1.map((record, index) => {
			  return [
				{ value: record.name },
				{ value: record.email },
				{ value: record.address },
				{ value: record.pin }
			  ];
			}),
		  },
		  {
			ySteps: 3, //will put space of 3 rows
			columns: [
			  { title: "Item Name" },
			  { title: "Item Unit" },
			  { title: "Quantity"},
			  { title: "Target Price" },
			  { title: "Negotiate Price"}
			],
			data: csvData2.map((record, index) => {
			  return [
				{ value: record.itemName },
				{ value: record.unit },
				{ value: record.quantity},
				{ value: record.target},
				{ value: record.negotiate}
			  ];
			}),
		  },  
	]

 //html content to the file starts here
	var htmlContent;

	if (order) {
		htmlContent = `
		<html>

		<head>
			<meta charset="UTF-8">
			<title>Invoice</title>
			<style>
				body {
					margin: 0;
					padding: 0;
					font-size: 16px;
					font-weight: 300;
					width: 40%;
					background: rgba(204, 204, 204, 0);
					font-family: 'Roboto Condensed', sans-serif;
				}
		
				h2,
				h4,
				p {
					margin: 0;
				}
		
				h6 {
					padding: 2px;
					margin-left: 78px;
				}
		
				.page {
					background: #fff;
					display: block;
					margin: 1rem auto 1rem auto;
					position: relative;
					box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
		
				}
		
				.page[size="A4"] {
					width: 21cm;
					height: 29.7cm;
					overflow-x: hidden;
				}
		
				.pdf-title {
					padding: 0px;
					font-size: 22px;
					text-align: right;
					margin-left: 390px;
					margin-top: -100px;
				}
		
				/* Top Section */
				.top-section {
					color: rgb(5, 5, 5);
					padding: 16px;
					height: 118px;
					width: 780px;
				}
		
				.top-section h2 {
					font-size: 35px;
					padding: 8px;
					margin-bottom: 4px;
					font-weight: 400;
				}
		
				.top-section .logo_sec {
					display: flex;
				}
		
				.top-section .logo {
					align-items: center;
				}
		
				.top-section .address {
					width: 50%;
					height: 100%;
					float: center;
					margin-left: 70px;
				}
		
				.top-section .address-content {
					max-width: 333px;
					padding: 0px;
					font-size: 10px;
					text-align: center;
					margin-left: 9px;
				}
		
				/*.top-section .pdf-title {
					padding: 15px;
					font-size: 16px;
					text-align: right;
					margin-left: 40px;
		
				}*/
		
				/*Billed_to*/
				.billed_to {
					padding: 0 20px;
				}
		
				.billed_to table {
					width: 85%;
					margin-left: 55px;
					margin-top: 122px;
				}
		
				.billed_to table,
				td {
					padding: 9px;
					text-align: center;
					font-size: 14px;
					border: 1px solid#616161;
					border-collapse: collapse;
				}
		
				.billed_to table,
				tr th {
					border: 1px solid#616161;
					border-collapse: collapse;
					text-align: center;
					padding: 4px;
				}
		
				/*Item shipped table*/
				.item_table {
					padding: 0px;
					display: flex;
				}
		
				.item_table table {
					width: 89%;
					margin-left: 40px;
					margin-top: 127px;
				}
		
				.item_table table,
				td {
					padding: 6px;
					text-align: center;
					font-size: 14px;
					border: 1px solid#616161;
					border-collapse: collapse;
				}
		
				.item_table table,
				tr th {
					border: 1px solid#616161;
					border-collapse: collapse;
					text-align: center;
					margin-left: 42px;
				}
			</style>
		</head>
		
		<body>
			<div class="page" size="A4">
				<div class="top-section">
					<div class="logo_sec">
						<div class="logo">
							<img src="/images/Asthara-Logo.png"
								style="width:65% ;height:initial">
						</div>
						<div class="address">
							<div class="address-content">
								<h2> Asthara-Agro </h2>
								<p> Transposeon Pvt Ltd, No 402, Kundalahalli Grama Main Road, B Block,
									Bhadra Brook Fields Apts, Bangalore, Karnataka, India - 560037 </p>
							</div>
							<div class="pdf-title">
								<h6>order</h6>
							</div>
						</div>
					</div>
				</div>
               
		<hr style="height: 2px;background:rgb(0, 0, 0);" >
		<div class="billed_to">
			<table id="table1">
				<tr>
					<th> Full_Name </th>
					<th> Email</th>
					<th> Address</th>
				</tr>
			    <tr>
					<td> ${order[0].name} </td>
					<td> ${order[0].email} </td>
					<td> ${order[0].address} , ${order[0].landmark} ,${order[0].district},
						${order[0].state} ,${order[0].country} ,${order[0].postal_code}
					</td>
				</tr>

			</table>
		</div>
		<div class="item_table">
			<table id="table2">
				<tr>
				    <th>Sr No.</th>
					<th>Item Name</th>
					<th>Unit</th>
					<th>Quantity</th>
					<th>Final Price</th>
					<th>Negotiate Price</th>
					<th>CGST Tax Rate</th>
                    <th>SGST Tax Rate</th>
                    <th>CGST Tax Amount</th>
                    <th>SGST Tax Amount</th>
                    <th>Total Amount</th>
				</tr>		
`;
	}
	
	else {
		htmlContent = `<h1>No data Available</h1>`;
	}

	function goBack() {
		history.goBack();
	}
    
	const createPDF = () => {
		noOfItems();
		var printContents = htmlContent;
		var originalContents = document.body.innerHTML;
		document.body.innerHTML = printContents;
		html2canvas(document.body).then(canvas => {
			document.body.appendChild(canvas);
			let base64image = canvas.toDataURL('image/png');
			const doc = new jsPDF({
				unit: "mm",
				format: [265, 350]
			});
			doc.addImage(base64image,'PNG',1,1);
			doc.save("vieworder.pdf");
			document.body.innerHTML = originalContents;
		});
	};

    const noOfItems = () => {
        var sum=0;
        for (var i = 0; i < Object.keys(order[0].items).length; i++) {
            var total=Number(order[0].items[i].itemNegotiatePrice);
            var tax=Number(total*0.09);
            var taxtotal=total+tax+tax;
            var fix=taxtotal.toFixed(2)
            htmlContent += `<tr>`;
            htmlContent += `<td> ${i + 1} `;
            htmlContent += `<td> ${order[0].items[i].itemName} </td>`;
            htmlContent += `<td> ${order[0].items[i].itemUnit}</td>`;
            htmlContent += `<td> ${order[0].items[i].quantity}</td>`;
            htmlContent += `<td> ${order[0].items[i].targetPrice}</td>`;
            htmlContent += `<td> ${order[0].items[i].itemNegotiatePrice}</td>`;
            htmlContent+=`<td>9%</td>`
            htmlContent+=`<td>9%</td>`
            htmlContent+=`<td>${tax}</td>`
            htmlContent+=`<td>${tax}</td>`
            htmlContent+=`<td>${fix}</td>`
            htmlContent += `</tr>`;
            sum+=total+tax+tax;
        }
        var s=sum.toFixed(2)
        htmlContent+=`<tr>`
        htmlContent+=`<th  style="text-align:right" colspan="10">Total :</th>`
            htmlContent+=`<td> ${s}</td>`
    htmlContent+=`</tr>`
        htmlContent += `</table>
                </div>
            </div>
        </body>
        </html>
        
        `;
    }


    return (
        <Provider theme={theme}>
            <SafeAreaView>
            <ScrollView>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Card style={styles.card}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Card.Title style={{ flex: 1,}} title="View Order"/>
						<View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
							<ExcelFile filename="vieworder" element={<FontAwesomeIcon color="green" size="35" icon={ faFileCsv } />}>
                			<ExcelSheet   dataSet={data} name="ViewOrder">
               				 </ExcelSheet>
            				</ExcelFile>
							</View>
                        <TouchableOpacity style={{ marginTop: '2%', padding: '1%' }} onPress={() => createPDF()}><FontAwesomeIcon icon={faFilePdf} color="red" size="35" /></TouchableOpacity>
                        <Button mode="contained" style={styles.button} onPress={()=>goBack()}>Go Back</Button>
                    </View>
                    <Card.Content>
                    {order ?
                        <>
                            <TextInput style={styles.input} mode="outlined" label="Full Name" value={order[0].name} />
                            <TextInput style={styles.input} mode="outlined" label="Email" value={order[0].email} />
                            <TextInput style={styles.input} mode="outlined" label="Mobile no" value={order[0].mobile_no} />
                            <TextInput style={styles.input} mode="outlined" label="Address" value={order[0].address} multiline rows={5} />
                            <TextInput style={styles.input} mode="outlined" label="Landmark" value={order[0].landmark} />
                            <TextInput style={styles.input} mode="outlined" label="District" value={order[0].district} />
                            <TextInput style={styles.input} mode="outlined" label="State" value={order[0].state} />
                            <TextInput style={styles.input} mode="outlined" label="Country" value={order[0].country} />
                            <TextInput style={styles.input} mode="outlined" label="Pin Code" value={order[0].postal_code} />
                            {order[0].items ?
                                <DataTable>
                                    <Title >All Items</Title>
                                    <DataTable.Header>
                                        <DataTable.Title>Item Name</DataTable.Title>
                                        <DataTable.Title>unit</DataTable.Title>
                                        <DataTable.Title>Quantity</DataTable.Title>
                                        <DataTable.Title>Final Price</DataTable.Title>
                                        <DataTable.Title>Negotiate Price</DataTable.Title>
                                    </DataTable.Header>
                                    
                                    {order[0].items.map((it) => (
                                        <>
                                            <DataTable.Row>
                                                <DataTable.Cell>{it.itemName}</DataTable.Cell>
                                                <DataTable.Cell>{it.itemUnit}</DataTable.Cell>
                                                <DataTable.Cell>{it.quantity}</DataTable.Cell>
                                                <DataTable.Cell>{it.targetPrice}</DataTable.Cell>
                                                <DataTable.Cell>{it.itemNegotiatePrice}</DataTable.Cell>
                                            </DataTable.Row>
                                        </>
                                    ))}
                                </DataTable>: null
                            }
                        </>:null
                    }
                    </Card.Content>
                </Card>
            </View>
            </ScrollView>
            </SafeAreaView>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        alignSelf: 'center',
        padding: '1%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                marginTop: '10%',
                marginBottom: '10%',
                width: '90%',
            },
            default: {
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
                marginTop: '4%',
                marginBottom: '4%',
                width: '75%',
            }
        })
    },
    input: {
        marginTop: '2%',
        width: '100%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                
            }
        })
    },
    button: {
        marginTop: '2%',
        flex: 1,
    },
}); 

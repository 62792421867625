import React, {useState,useEffect} from 'react';
import { View, StyleSheet, Platform, ScrollView, SafeAreaView } from 'react-native';
import { Card, Button, Provider, DefaultTheme, DataTable, TextInput } from 'react-native-paper';
import { useHistory } from "react-router-dom";
import {url} from '../../utils/url';
import axios from 'axios';
import { Mandi_Order_by_id } from '../../services/mandi_api';
import { roleas } from '../../utils/user';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#0cc261',
        accent: '#f1c40f',
    },
};

export default function EditMandiOrder(props, { navigation, route }) {

    var id = "";
    if(Platform.OS=="android"){
        id = route.params.id;
    }
    else{
        id = props.match.params.id;
    }

    const [allItems, setAllItems] = useState();
    const [role, setRole] = useState('');
    const [charge, setCharge] = useState('0');
    const [status, setStatus] = useState("");

    let history = useHistory();

    useEffect(() => {

        if(id){
            Mandi_Order_by_id(id)
            .then(result => {
                setAllItems(result[0].items);
                setCharge(result[0].charge);
                setStatus(result[0].status);
            })
        }

        roleas()  
        .then(result => {
            setRole(result);
        })

    }, [id]);

    function priceChange(index, val){
        const value = [...allItems];
        value[index].selling_price=val;
        setAllItems(value);
    }

    function statusChange(index, val){
        const value = [...allItems];
        value[index].status=val;
        setAllItems(value);
        submitForm(status);
    }

    function submitForm(val) {
        axios.put(url + '/update_mandi_order/'+id, {
                items: allItems,
                charge: charge,
                status: val
          })
          .then(function (response) {
            alert(response.data.message);
            if(response.data && val=="Sold")
            {
                if(Platform.OS=="android"){
                    navigation.navigate('AllOrders');

                }
                else{
                    history.push('/allmandiorders');
                }
            }
        }) 
    }

    const getColor = (itemNegotiatePrice,targetPrice) => {
        let BackgroundColor = '';
        if(targetPrice && itemNegotiatePrice){    
            if(itemNegotiatePrice <= 0) {
                BackgroundColor = '';
            } else if(itemNegotiatePrice >= targetPrice) {
                BackgroundColor = 'lightgreen';
            } else if(itemNegotiatePrice >= ((targetPrice / 100) * 95)) {
                BackgroundColor = 'yellow';
            } else if(itemNegotiatePrice >= ((targetPrice / 100) * 85)) {
                BackgroundColor = 'orange';
            } else BackgroundColor = 'red';
        }
        return BackgroundColor;
    }

    return (
        <Provider theme={theme}>
            <SafeAreaView>
            <ScrollView>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Card style={styles.card}>
                    <Card.Title titleStyle={styles.title} title="Edit Mandi Order"/>
                    <Card.Content>
                        <DataTable style={styles.datatable}>
                            <DataTable.Header>
                                <DataTable.Title>Item</DataTable.Title>
                                <DataTable.Title>Unit</DataTable.Title>
                                <DataTable.Title>Quantity</DataTable.Title>
                                <DataTable.Title>Target Price</DataTable.Title>
                                <DataTable.Title>Selling Price</DataTable.Title>
                                <DataTable.Title>Status</DataTable.Title>
                                {status=="Pending for Sell" ?
                                    <DataTable.Title>Action</DataTable.Title>
                                    :
                                    <DataTable.Title>Total Price</DataTable.Title>
                                }
                            </DataTable.Header>

                            {allItems && allItems.map((it, index) => (
                                <DataTable.Row style={{ backgroundColor: getColor(it.selling_price, it.target_price) }} >
                                    <DataTable.Cell>{it.item_name+" ("+it.grade+")"}</DataTable.Cell>
                                    <DataTable.Cell>{it.unit}</DataTable.Cell>
                                    <DataTable.Cell>{it.quantity}</DataTable.Cell>
                                    <DataTable.Cell>{it.target_price}</DataTable.Cell>
                                    <DataTable.Cell><TextInput style={styles.input} mode="outlined" label="Selling Price" value={it.selling_price} onChange={(e)=>priceChange(index, e.target.value)} /></DataTable.Cell>
                                    <DataTable.Cell>{it.status}</DataTable.Cell>
                                    {status=="Pending for Sell" ?
                                        <DataTable.Cell>
                                            {role && role=="manager" &&
                                                <>
                                                {it.status=="Requested for Approve" &&
                                                    <View style={{flexDirection: 'row'}}>
                                                        <Button mode="contained" style={styles.button} onPress={()=>statusChange(index, "Approved")} >Approve</Button>
                                                        <Button mode="contained" style={styles.button} onPress={()=>statusChange(index, "Rejected")} color="red" >Reject</Button>
                                                    </View>
                                                }
                                                </>
                                            }
                                            {role && role=="buyer" &&
                                                <>
                                                {(it.status=="pending" || it.status=="Rejected") &&
                                                    <Button mode="contained" style={styles.button} onPress={()=>statusChange(index, "Requested for Approve")} >Request Sell</Button>
                                                }
                                                {it.status=="Approved" &&
                                                    <Button mode="contained" style={styles.button} onPress={()=>statusChange(index, "Sold")} >Sell Now</Button>
                                                }
                                                </>
                                            }
                                        </DataTable.Cell>
                                        :
                                        <DataTable.Cell>{it.quantity*it.selling_price}</DataTable.Cell>
                                    }
                                </DataTable.Row>
                            ))}
                        </DataTable>
                        <TextInput mode="outlined" label="Transport & Labour Charge" value={charge} onChange={(e)=>setCharge(e.target.value)} />
                        {role=="buyer" && status=="Pending for Sell" &&
                            <>
                                <Button mode="contained" style={styles.button} onPress={()=>submitForm("Sold")} >Complete Order</Button>
                            </>
                        }
                    </Card.Content>
                </Card>
            </View>
            </ScrollView>
            </SafeAreaView>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        alignSelf: 'center',
        padding: '1%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                marginTop: '10%',
                marginBottom: '10%',
                width: '90%',
            },
            default: {
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
                marginTop: '4%',
                marginBottom: '4%',
                width: '75%',
            }
        })
    },
    input: {
        marginTop: '2%',
        width: '70%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                
            }
        })
    },
    title: {
        ...Platform.select({
            ios: {
                
            },
            android: {
                textAlign: 'center',
                color: 'green',
                fontFamily: 'Roboto'
            },
            default: {
                textAlign: 'center',
                color: 'green',
                fontSize: 28,
                fontFamily: 'Roboto'
            }
        })
    },
    button: {
        marginTop: '2%',
    },
    customer: {
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                flexDirection: 'row',
                justifyContent: 'space-between',
            }
        })
    }
}); 
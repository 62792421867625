import {url} from '../utils/url';
import axios from 'axios';
//retrive all items
export const allMandiOrder = () => {
    return axios.get(url + '/retrive_all_mandi_order')
    .then(res => {
        return res.data;
    }).catch(err => console.log(err))
}

//retrive all order by id 
export const Mandi_Order_by_id = (id) => {
    return axios.get(url + '/retrive_mandi_order/'+ id)
    .then(res => {
        return res.data;
    }).catch(err => console.log(err))
}

import React from 'react'

const PageNotFound = () => {
    return (
        <div id="wrapper">
            <div id="info">
                <h3>This page could not be found</h3>
            </div>
        </div >
    )
}
export default PageNotFound;
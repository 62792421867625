import React, {useState,useEffect} from 'react';
import { View, StyleSheet, Platform, ScrollView, SafeAreaView, TouchableOpacity } from 'react-native';
import { TextInput, Card, Provider, DefaultTheme, DataTable, Title, Button, Portal, Modal } from 'react-native-paper';
import { Order_by_id } from '../../../services/order_api';
import { useHistory } from 'react-router-dom';
import { order_status_by_orderId } from '../../../services/report/order_status';
import axios from 'axios';
import { url } from '../../../utils/url';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#0cc261',
        accent: '#f1c40f',
    },
};

export default function ViewOrderSummary(props, {navigation, route}) {

    let history = useHistory();

    var orderid = "";
    if(Platform.OS=="android"){
        orderid = route.params.orderId;
    }
    else{
        orderid = props.match.params.orderid;
    }
    
    const [order, setOrder] = useState();
    const [allOrderStatus, setAllOrderStatus] = useState();
    const [visible, setVisible] = useState(false);
    const [it, setIt] = useState();

    useEffect(() => {

        if(orderid){
            Order_by_id(orderid)
            .then(result=> {
                setOrder(result);
            })
        }

        if(order){
            var date=order[0].order_date.substring(0,10);
            var d=new Date(order[0].order_date);
            d.toTimeString();
            d=String(d);
            var hour=d.substring(16,18);
            var custom_orderId=order[0].nick_name+"_"+order[0].postal_code+"_"+date+"_"+hour;

            if(custom_orderId){
                order_status_by_orderId(custom_orderId)  
                .then(result => {
                    setAllOrderStatus(result);
                })
            }
        }

    }, [orderid, order]);

    function goBack(){
        history.goBack();
    }

    function makeDelivery(){
        axios.post(url + '/make_order_delivery', {
            order: order,
        })
        .then(function (response) {
        alert(response.data.message);
        if(response.data)
        {
            if(Platform.OS=='android'){
                navigation.navigate("/makeorderdelivery/"+orderid);
            }
            else{
                history.push("/makeorderdelivery/"+response.data.data._id);
            }
        }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    const showModal = (val) => {
        setVisible(true);
        setIt(val);
    };
    const hideModal = () => setVisible(false);

    var htmlContent ;
    if(order){
        htmlContent=`
        <html>

        <head>
            <meta charset="UTF-8">
            <title>Invoice</title>
            <style>
                body {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    font-weight: 300;
                    width: 40%;
                    background: rgba(204, 204, 204, 0);
                    font-family: 'Roboto Condensed', sans-serif;
                }
        
                h2,
                h4,
                p {
                    margin: 0;
                }
        
                h6 {
                    padding: 2px;
                    margin-left: 78px;
                }
        
                .page {
                    background: #fff;
                    display: block;
                    margin: 1rem auto 1rem auto;
                    position: relative;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        
                }
        
                .page[size="A4"] {
                    width: 21cm;
                    height: 29.7cm;
                    overflow-x: hidden;
                }
        
                .pdf-title {
                    padding: 0px;
                    font-size: 16px;
                    text-align: right;
                    margin-left: 370px;
                    margin-top: -120px;
                }
        
                /* Top Section */
                .top-section {
                    color: rgb(5, 5, 5);
                    padding: 16px;
                    height: 118px;
                    width: 780px;
                }
        
                .top-section h2 {
                    font-size: 35px;
                    padding: 8px;
                    margin-bottom: 4px;
                    font-weight: 400;
                }
        
                .top-section .logo_sec {
                    display: flex;
                }
        
                .top-section .logo {
                    align-items: center;
                }
        
                .top-section .address {
                    width: 50%;
                    height: 100%;
                    float: center;
                    margin-left: 70px;
                }
        
                .top-section .address-content {
                    max-width: 333px;
                    padding: 0px;
                    font-size: 10px;
                    text-align: center;
                    margin-left: 9px;
                }
        
                /*Billed_to*/
                .billed_to {
                    padding: 0 20px;
                }
        
                .billed_to table {
                    width: 85%;
                    margin-left: 55px;
                    margin-top: 122px;
                }
        
                .billed_to table,
                td {
                    padding: 9px;
                    text-align: center;
                    font-size: 12px;
                    border: 1px solid#616161;
                    border-collapse: collapse;
                }
        
                .billed_to table,
                tr th {
                    border: 1px solid#616161;
                    border-collapse: collapse;
                    text-align: center;
                    padding: 4px;
                }
        
                /*Item shipped table*/
                .item_table {
                    padding: 0px;
                    display: flex;
                }
        
                .item_table table {
                    width: 89%;
                    margin-left: 40px;
                    margin-top: 127px;
                }
        
                .item_table table,
                td {
                    padding: 6px;
                    text-align: center;
                    font-size: 12px;
                    border: 1px solid#616161;
                    border-collapse: collapse;
                }
        
                .item_table table,
                tr th {
                    border: 1px solid#616161;
                    border-collapse: collapse;
                    text-align: center;
                    margin-left: 42px;
                }
            </style>
        </head>
        
        <body>
            <div class="page" size="A4">
                <div class="top-section">
                    <div class="logo_sec">
                        <div class="logo">
                            <img src="/images/Asthara-Logo.png"
                                style="width:65% ;height:initial">
                        </div>
                        <div class="address">
                            <div class="address-content">
                                <h2> Asthara-Agro </h2>
                                <p> Transposeon Pvt Ltd, No 402, Kundalahalli Grama Main Road, B Block,
                                    Bhadra Brook Fields Apts, Bangalore, Karnataka, India - 560037 </p>
                            </div>
                            <div class="pdf-title">
                                <h6>delivered order</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="height: 2px;background:rgb(0, 0, 0);">
                <div class="billed_to">
                    <table>
                        <tr>
                            <th> Full_Name </th>
                            <th> Email</th>
                            <th> Address</th>
                        </tr>
                        <tr>
                            <td> ${order[0].name} </td>
                            <td> ${order[0].email} </td>
                            <td> ${order[0].address} , ${order[0].landmark} ,${order[0].district},
                                ${order[0].state} ,${order[0].country} ,${order[0].postal_code}
                            </td>
                        </tr>
        
                    </table>
                </div>
                <div class="item_table">
                    <table>
                        <tr>
                            <th>Sr No.</th>
                            <th>Item Name</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Final Price</th>
                            <th>Negotiate Price</th>
                            <th>Status</th>
                            <th>CGST Tax Rate</th>
                            <th>SGST Tax Rate</th>
                            <th>CGST Tax Amount</th>
                            <th>SGST Tax Amount</th>
                            <th>Total Amount</th>
                        </tr>
        
    `;
        }
        
        else{
            htmlContent=`<h1>No data Available</h1>`;
        }

        function goBack(){
            history.push('/ordersummary');
        }    
   

    function createPDF(){
        noOfItems();
        var printContents = htmlContent;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        html2canvas(document.body).then(canvas => {
            document.body.appendChild(canvas);
            let base64image = canvas.toDataURL('image/png');
            const doc = new jsPDF({
				unit: "mm",
				format: [265, 350]
			});
            doc.addImage(base64image, 'PNG', 0,0);
            doc.save("order_summary.pdf");
            document.body.innerHTML = originalContents;
        });
    }
    const noOfItems=() => {
        var sum=0;
        for(var i=0;i<Object.keys(order[0].items).length;i++){   
                       //loop for retrieving data from the database
            var total=Number(order[0].items[i].itemNegotiatePrice);
            var tax=total*0.09;
            var taxtotal=total+tax+tax;
		    var fix=taxtotal.toFixed(2);
            htmlContent+=`<tr>`;
            htmlContent+=`<td> ${i+1} `;
            htmlContent+=`<td> ${order[0].items[i].itemName} </td>`;
            htmlContent+=`<td> ${order[0].items[i].itemUnit}</td>`;
            htmlContent+=`<td> ${order[0].items[i].quantity}</td>`;
            htmlContent+=`<td> ${order[0].items[i].targetPrice}</td>`;
            htmlContent+=`<td> ${order[0].items[i].itemNegotiatePrice}</td>`;
            htmlContent+=`<td> ${order[0].status}</td>`;
            htmlContent+=`<td>9%</td>`
		    htmlContent+=`<td>9%</td>`
		    htmlContent+=`<td>${tax}</td>`
		    htmlContent+=`<td>${tax}</td>`
		    htmlContent+=`<td>${fix}</td>`
		    htmlContent += `</tr>`;
		    sum+=total+tax+tax;
        }
        var s=sum.toFixed(2)
	    htmlContent+=`<tr>`
	    htmlContent+=`<th  style="text-align:right" colspan="11">Total :</th>`
		htmlContent+=`<td> ${s}</td>`
        htmlContent+=`</tr>`
         htmlContent+=`</table>
         </div>
     </div>
    </body>
    </html>
    `;
    }


    function makeDelivery(){
        axios.post(url + '/make_order_delivery', {
            order: order,
        })
        .then(function (response) {
        alert(response.data.message);
        if(response.data)
        {
            if(Platform.OS=='android'){
                navigation.navigate("/makeorderdelivery/"+orderid);
            }
            else{
                history.push("/makeorderdelivery/"+response.data.data._id);
            }
        }
        })
        .catch(function (error) {
            console.log(error);
        });
    }

    let data = []
      if(order){
     if(order[0].items){
      order[0].items.map(it => {
       
          data.push({
              
              name: it.itemName,
              unit: it.itemUnit,
              quant: it.quantity,
              target:it.targetPrice,
              negotiate:it.itemNegotiatePrice,
             
          });
          
      });
    }
    }

    const containerStyle = {backgroundColor: 'white',width: '50%', alignSelf: 'center'};

    return (
        <Provider theme={theme}>
            <SafeAreaView>
            <ScrollView>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Portal>
                    <Modal visible={visible} onDismiss={hideModal} contentContainerStyle={containerStyle}>
                        <>
                            <DataTable>
                                <DataTable.Header>
                                    <DataTable.Title>Item Name</DataTable.Title>
                                    <DataTable.Title>Quantity</DataTable.Title>
                                    <DataTable.Title>status</DataTable.Title>
                                </DataTable.Header>
                                {it &&
                                    it.map((item)=>{
                                        return (
                                            <>
                                                <DataTable.Row>
                                                    <DataTable.Cell>{item.item_name+" ("+item.item_grade+")"}</DataTable.Cell>
                                                    <DataTable.Cell>{item.quantity}</DataTable.Cell>
                                                    <DataTable.Cell>{item.status}</DataTable.Cell>
                                                </DataTable.Row>
                                            </>
                                        )
                                    })
                                }
                            </DataTable>
                        </>
                    </Modal>
                </Portal>
                <Card style={styles.card}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Card.Title style={{ flex: 1,}} title="View Order Summary"/>
                        <TouchableOpacity style={{marginTop : '2%', padding: '1%'}} onPress={() => createPDF()}><FontAwesomeIcon icon={ faFilePdf } color="red" size="35" /></TouchableOpacity>
                        <Button mode="contained" style={styles.button} onPress={()=>goBack()}>Go Back</Button>
                    </View>
                    <Card.Content>
                    {order &&
                        <>
                            <TextInput style={styles.input} mode="outlined" label="Full Name" value={order[0].name} />
                            <TextInput style={styles.input} mode="outlined" label="Email" value={order[0].email} />
                            <TextInput style={styles.input} mode="outlined" label="Mobile no" value={order[0].mobile_no} />
                            <TextInput style={styles.input} mode="outlined" label="Address" value={order[0].address} multiline rows={5} />
                            <TextInput style={styles.input} mode="outlined" label="Landmark" value={order[0].landmark} />
                            <TextInput style={styles.input} mode="outlined" label="District" value={order[0].district} />
                            <TextInput style={styles.input} mode="outlined" label="State" value={order[0].state} />
                            <TextInput style={styles.input} mode="outlined" label="Country" value={order[0].country} />
                            <TextInput style={styles.input} mode="outlined" label="Pin Code" value={order[0].postal_code} />
                            {order[0].items && 
                                <DataTable>
                                    <Title >All Items</Title>
                                    <DataTable.Header>
                                        <DataTable.Title>Item Name</DataTable.Title>
                                        <DataTable.Title>unit</DataTable.Title>
                                        <DataTable.Title>Quantity</DataTable.Title>
                                        <DataTable.Title>Final Price</DataTable.Title>
                                        <DataTable.Title>Negotiate Price</DataTable.Title>
                                        <DataTable.Title>status</DataTable.Title>
                                        <DataTable.Title>Action</DataTable.Title>
                                    </DataTable.Header>
                                    
                                    {allOrderStatus && order[0].items.map((it) => {
                                        var val=allOrderStatus.filter(o => o.item_name === it.itemName);
                                        var q=0;
                                        for(var i=0; i<val.length; i++){
                                            if(val[i].status === "Reached at Sales Hub" || val[i].status === "Out for Delivery"){
                                                q+=parseInt(val[i].quantity);
                                            }
                                        }
                                        var s={backgroundColor: 'orange'};
                                        if(q==it.quantity && val[0].status === "Reached at Buyer Hub"){
                                            s={backgroundColor: 'lightgreen'};
                                        }
                                        else if(q==it.quantity && val[0].status === "Out for Delivery"){
                                            s={backgroundColor: 'lightblue'};
                                        }
                                        return (
                                            <>
                                                <DataTable.Row style={s}>
                                                    <DataTable.Cell>{it.itemName}</DataTable.Cell>
                                                    <DataTable.Cell>{it.itemUnit}</DataTable.Cell>
                                                    <DataTable.Cell>{it.quantity}</DataTable.Cell>
                                                    <DataTable.Cell>{it.targetPrice}</DataTable.Cell>
                                                    <DataTable.Cell>{it.itemNegotiatePrice}</DataTable.Cell>
                                                    <DataTable.Cell>{val[0].status}</DataTable.Cell>
                                                    <DataTable.Cell><Button onPress={()=>showModal(val)}>Details</Button></DataTable.Cell>
                                                </DataTable.Row>
                                            </>
                                        )
                                    })}
                                </DataTable>
                            }
                        </>
                    }
                    {Platform.OS=='android' ?
                        <Button mode="contained" style={{width: '100%'}} onPress={() => {navigation.navigate('EditOrder', {itemId: orderid})}}>Make Delivery Now</Button>
                        :
                        <Button mode="contained" onPress={() => makeDelivery()} style={{width: '100%', marginTop: '20px'}}>Make Delivery Now</Button>
                    }
                    </Card.Content>
                </Card>
            </View>
            </ScrollView>
            </SafeAreaView>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        alignSelf: 'center',
        padding: '1%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                marginTop: '10%',
                marginBottom: '10%',
                width: '90%',
            },
            default: {
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
                marginTop: '4%',
                marginBottom: '4%',
                width: '75%',
            }
        })
    },
    input: {
        marginTop: '2%',
        width: '100%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                
            }
        })
    },
    button: {
        marginTop: '2%',
        flex: 1,
    },
}); 

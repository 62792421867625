import React from 'react';
import NavBar from './navbar';

export default function App() {
    return (
        <>
            <NavBar/>
        </>
    )
}

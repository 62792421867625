import React, { useState, useEffect } from 'react';
import { View, StyleSheet, Platform, Text, TouchableOpacity } from 'react-native';
import { TextInput, Card, Provider, DefaultTheme, DataTable } from 'react-native-paper';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { url } from '../../../utils/url';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#0cc261',
        accent: '#f1c40f',
    },
};

export default function View_Completed_Purchase_Order(props, { route }) {

    var id = "";
    var pickupConfirmId = "";
    if (Platform.OS == "android") {
        id = route.params.id;
    }
    else {
        pickupConfirmId = props.match.params.id;
    }

    const [visible2, setVisible2] = useState(false);

    const openMenu2 = () => setVisible2(true);
    const closeMenu2 = () => setVisible2(false);

    const [pickupAssignId, setPickupAssignId] = useState("");
    const [order_id, setOrderId] = useState("")
    const [buyer_id, setBuyerId] = useState("Choose Buyer");
    const [items, setItems] = useState();
    const [vendor_id, setVendorId] = useState("Choose Vendor");
    const [host, setHost] = useState("");
    const [vNumber, setVNumber] = useState("");
    const [driverName, setDriverName] = useState("");
    const [driverMobileNumber, setDriverMobileNumber] = useState("");
    const [labourName, setLabourName] = useState("");
    const [labourMobileNumber, setLabourMobileNumber] = useState("");

    useEffect(() => {

        if (Platform.OS == "android") {
            setHost("10.0.2.2");
            setPickupAssignId(id);
        }
        else {
            setHost("localhost");
            setPickupAssignId(pickupConfirmId);
            setOrderId(order_id);
        }

        if (pickupAssignId) {
            fetch(`${url}/retrive_completed_purchase_order/${pickupConfirmId}`, {
                method: 'GET'
            })
                .then(res => res.json())
                .catch(error => console.log(error))
                .then(item => {
                    setOrderId(item[0].purchase_order.custom_orderId);
                    setItems(item[0].purchase_order.items);
                    setVendorId(item[0].purchase_order.custom_vendorId);
                    setBuyerId(item[0].purchase_order.buyer_id);
                    setVNumber(item[0].vehicle_number);
                    setDriverName(item[0].driver_name);
                    setDriverMobileNumber(item[0].driver_mobile_no);
                    setLabourName(item[0].labour_name);
                    setLabourMobileNumber(item[0].labour_mobile_no);
                });
        }

    }, [host, pickupAssignId, order_id, pickupConfirmId, id]);

    const ItemChange = (index, fieldname, fieldvalue, itemId, unit) => {
        const values = [...items];
        if (fieldname === "item") {
            values[index].itemId = itemId;
            values[index].itemName = fieldvalue;
            values[index].itemUnit = unit;
        }
        else {
            values[index].quantity = fieldvalue;
        }
        setItems(values);
    };

    const ItemChange2 = (index, fieldname, fieldvalue, itemId, unit) => {
        const values = [...items];
        if (fieldname === "item") {
            values[index].itemId = itemId;
            values[index].itemName = fieldvalue;
            values[index].itemUnit = unit;
        }
        else {
            values[index].itemPrice = fieldvalue;
        }
        setItems(values);
    };
   
    var htmlContent;

    if (order_id && vNumber && driverName && driverMobileNumber && labourName && labourMobileNumber && items) {
        htmlContent = `
        <html>
        <head>
        <meta charset="UTF-8">
        <title>Invoice</title>
    
        <style>
            body {
                margin: 0;
                padding: 0;
                font-size: 16px;
                font-weight: 300;
                width: 40%;
                background:  rgba(204, 204, 204, 0);		
                font-family: 'Roboto Condensed', sans-serif;
            }
    
            h2, h5, p {
                margin: 0;
            }
            
            .page {
                background: #fff;
                display: block;
                margin: 1rem auto 1rem auto;
                position: relative;
            }
    
            .page[size="A4"] {
                width: 21cm;
                height: 29.7cm;
                overflow-x: hidden;
            }
            
            /* Top Section */
            .top-section {
                padding: 16px;
			    height: 145px;
			    width: 815px;
            }
    
            .top-section h2 {
                font-size: 35px;
			    padding: 8px;
			    margin-bottom: 4px;
			    font-weight: 400;
            }
            
            .top-section .logo_sec{
                display: flex;
            }
    
            .top-section .logo{
                align-items: center;
            }
    
            .top-section .address {
                width: 55%;
                height: 100%;
                float: center;
                margin-left: 70px;
            }
    
            .top-section .address-content {
                max-width: 350px;
                padding:15px;
                font-size:10px;
                text-align: center;
                margin-left: 24px;
               
            }
    
            .top-section .pdf-title {
                padding:15px;
                font-size:16px;
                text-align: right;
                margin-left: 40px;
               
            }
    
            /*Shipment table*/
            .ship_table{
                padding:0 20px;
            }
            .ship_table table{
                width:78%;
                margin-left: 70px;
                margin-top: 70px;
            }
     
            .ship_table table,td {
                padding:2px;
                text-align:center;
                font-size: 14px;
                border:1px solid#616161;
                border-collapse:collapse;
            }
    
            .ship_table table ,tr th{
                border:1px solid#616161;
                border-collapse:collapse;
                text-align:left;
            }
    
            /*Item shipped table*/
            .item_table{
                padding:0 30px;
            }
            .item_table table{
                width:70%;
                margin-left: 70px;
                margin-top: 127px;
            }
     
            .item_table table,td {
                padding:6px;
                text-align:left;
                font-size: 14px;
                border:1px solid#616161;
                border-collapse:collapse;
            }
    
            .item_table table ,tr th{
                border:1px solid#616161;
                border-collapse:collapse;
                text-align:left;
                background-color: rgb(255, 255, 255);
            }
    
        </style>
    </head> 
    <body>
        <div class="page" size="A4">
            <div class="top-section">
                <div class="logo_sec">
                    <div class="logo">
                        <img src="/images/Asthara-Logo.png" style="width:80% ;height:initial" >
                    </div>	
                    <div class="address">
                        <div class="address-content">
                            <h2> Asthara-Agro </h2>
                            <p> Transposeon Pvt Ltd, No 402, Kundalahalli Grama Main Road, B Block, 
                            Bhadra Brook Fields Apts, Bangalore, Karnataka, India - 560037 </p>
                        </div>
                    </div>
                    <div class="pdf-title">
                        <h6>dispatch_order</h6>
                     </div>
                </div>
             </div>
            <hr style="height: 2px;background:rgb(0, 0, 0);" >
            <div class="ship_table">
                <table>
                    <tr><th> order_id </th>
                        <td> ${order_id}</td>
                    </tr>
                    <tr><th> Vehicle Number</th>
                        <td> ${vNumber}</td>
                    </tr>
                    <tr><th> Driver Name</th>
                        <td> ${driverName}</td>
                    </tr>
                    <tr><th> Driver Number</th>
                        <td> ${driverMobileNumber}</td>
                    </tr>
                    <tr><th> Labour Name </th>
                        <td> ${labourName}</td>
                    </tr>
                    <tr><th> Labour Number</th>
                        <td> ${labourMobileNumber}</td>
                    </tr>
                </table>
            </div>
            <div class="item_table">
                <table>
                    <tr>
                        <th>Item</th>
                        <th>Unit</th>
                        <th>Quantity</th>
                    </tr>
                    <tr>
                        <td> ${items.itemName}(${items.Grade})</td>
                        <td> ${items.itemUnit}</td>
                        <td> ${items.quantity}</td>
                    </tr>
                </table>
            </div>
        </div>
    </body>
    </html>					
`;
    }
    else {
        htmlContent = `<h1>No data Available</h1>`;
    }

    const createPDF = () => {
        var printContents = htmlContent;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        html2canvas(document.body).then(canvas => {
            document.body.appendChild(canvas);
            let base64image = canvas.toDataURL('image/png');
            const doc = new jsPDF({
				unit: "mm",
				format: [265, 350]
			});
            doc.addImage(base64image, 'PNG', 0,0);
            doc.save("dispatchorder.pdf");
            document.body.innerHTML = originalContents;
        });

    };

    return (
        <Provider theme={theme}>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Card style={styles.card}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Card.Title title="View Details of dispatch Order items" />
                        <TouchableOpacity onPress={() => createPDF()}><FontAwesomeIcon icon={faFilePdf} color="red" size="35" /></TouchableOpacity>
                    </View>
                    <Card.Content>
                        {order_id ?
                            <TextInput style={styles.input} mode="outlined" label="Order Id" value={order_id} /> : null
                        }

                        {vNumber ?
                            <TextInput style={styles.input} mode="outlined" label="Vehicle Number" value={vNumber} /> : null
                        }

                        {driverName ?
                            <TextInput style={styles.input} mode="outlined" label="Driver Name" value={driverName} /> : null
                        }

                        {driverMobileNumber ?
                            <TextInput style={styles.input} mode="outlined" label="Driver Number" value={driverMobileNumber} /> : null
                        }

                        {labourName ?
                            <TextInput style={styles.input} mode="outlined" label="Labour Name" value={labourName} /> : null
                        }

                        {labourMobileNumber ?
                            <TextInput style={styles.input} mode="outlined" label="Labour Number" value={labourMobileNumber} /> : null
                        }

                        {items ?
                            <DataTable style={styles.datatable}>
                                <DataTable.Row style={styles.input}>
                                    <DataTable.Cell><TextInput mode="outlined" label="Item" value={items.itemName + " (" + items.Grade + ")"} /></DataTable.Cell>
                                    <DataTable.Cell><TextInput mode="outlined" label="Unit" value={items.itemUnit} /></DataTable.Cell>
                                    <DataTable.Cell><TextInput keyboardType='numeric' mode="outlined" label="Quantity" value={items.quantity} onChangeText={(text) => ItemChange(0, "quantity", text, '')} /></DataTable.Cell>
                                    <DataTable.Cell><TextInput keyboardType='numeric' mode="outlined" label="Price" value={items.itemPrice} onChangeText={(text) => ItemChange2(0, "itemPrice", text, '')} /></DataTable.Cell>
                                </DataTable.Row>
                            </DataTable> : null
                        }
                    </Card.Content>
                </Card>
            </View>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        alignSelf: 'center',
        padding: '1%',
        ...Platform.select({
            ios: {

            },
            android: {
                marginTop: '10%',
                marginBottom: '10%',
                width: '90%',
            },
            default: {
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
                marginTop: '4%',
                marginBottom: '4%',
                width: '75%',
            }
        })
    },
    input: {
        marginTop: '2%',
        width: '100%',
        backgroundColor: 'white',
        ...Platform.select({
            ios: {

            },
            android: {

            },
            default: {

            }
        })
    },
    button: {
        marginTop: '2%',
    }
}); 
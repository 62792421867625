import React, {useState, useEffect} from 'react';
import { View, StyleSheet, Platform, ActivityIndicator, ScrollView, SafeAreaView } from 'react-native';
import { Provider, DefaultTheme, Button, Title, DataTable, Searchbar, Text, Menu } from 'react-native-paper';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faSearch, faTimes, faEye, faSort, faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { users_by_id } from '../../services/user_api';
import { roleas, loginuserId } from '../../utils/user';
import { allAssignedSales } from '../../services/asignsales_api';
import  {all_users_by_role, user_category, user_address} from '../../services/user_api';
import {Delivered_orders} from '../../services/report/delivered'

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#0cc261',
        accent: '#f1c40f',
    },
};

export default function AllScrapOrders({ navigation }, props) {

    const [visible1, setVisible1] = useState(false);
    const [searchQuery, setSearchQuery] = useState('');
    const [allOrders, setAllOrders] = useState([]);
    const [userId, setUserId] = useState('');
    const [sorting_order, setSortingOrder] = useState('ASC');
    const [userCategory, setUserCategory] = useState();
    const [category, setCategory] = useState("Choose Category");
    const [categoryId, setCategoryId] = useState("");
    const [roleas, setRoleas] = useState("");
    const [national, setNational] = useState();
    const [district, setDistrict] = useState();
    const [regional, setRegional] = useState();
    const [distributor, setDistributor] = useState();
    const [national_email, setNationalEmail] = useState("Choose National Sales");
    const [regional_email, setRegionalEmail] = useState("Choose Regional Sales");
    const [district_email, setDistrictEmail] = useState("Choose District Sales");
    const [visible3, setVisible3] = useState(false)
    const [allUpdateDelivery, setAllUpdateDelivery] = useState();
    const [host, setHost] = useState("");
    useEffect(() => {
        Delivered_orders()
        .then(result=>{
            setAllUpdateDelivery(result);
        })

        allAssignedSales()
        .then(result=> {
            setAllOrders(result);
        })
        user_category()
        .then(function(result) {
            setUserCategory(result);
        });

        setRoleas(props.roleas);

        loginuserId()  
        .then(result => {
            setUserId(result);
        })

        all_users_by_role("National Sales")
        .then(result => {
            setNational(result);
        })

        all_users_by_role("Regional Sales")
        .then(result => {
            setRegional(result);
        })

        all_users_by_role("District Sales")
        .then(result => {
            setDistrict(result);
        })

        all_users_by_role("sales")
        .then(result => {
            setDistributor(result);
        })

    }, [userId, props.roleas, allUpdateDelivery]);
// console.log(distributor);
    const sorting = (col)=>{
        if(sorting_order=="ASC"){
            const sorted=([...allOrders].sort((a,b)=>
            a[col].toLowerCase()>b[col].toLowerCase() ?1:-1));
            setAllOrders(sorted);
            setSortingOrder('DES');
        }
        if(sorting_order=="DES"){
            const sorted=([...allOrders].sort((a,b)=>
            a[col].toLowerCase()<b[col].toLowerCase() ?1:-1));
            setAllOrders(sorted);
            setSortingOrder('ASC');
        }
    }
    const openMenu1 = () => setVisible1(true);
    const closeMenu1 = () => setVisible1(false);

    const openMenu3 = () => setVisible3(true);
    const closeMenu3 = () => setVisible3(false);

    const onChangeSearch = query => setSearchQuery(query);

    function chooseCategory(id, name) {
        setCategoryId(id);
        setCategory(name);
        closeMenu1();
    }

    function chooseNational(id,email){
        setNationalEmail(email);
        closeMenu3();
    }

    function chooseregional(id, email){
        setRegionalEmail(email);
        closeMenu3();
    }

    function chooseDistrict(id,email){
        setDistrictEmail(email);
        closeMenu3();
    }
    return (
        <Provider theme={theme}>
        <SafeAreaView>
        <ScrollView>
            <View>
                <DataTable style={styles.datatable}>
                <Title style={styles.title} >All Assigned Sales</Title>
                <Menu
                    visible={visible1}
                    onDismiss={closeMenu1}
                    anchor={<Button style={styles.input} mode="outlined" onPress={openMenu1}>{category}</Button>}>
                        <Searchbar
                            icon={() => <FontAwesomeIcon icon={ faSearch } />}
                            clearIcon={() => <FontAwesomeIcon icon={ faTimes } />}
                            placeholder="Search"
                            onChangeText={onChangeSearch}
                            value={searchQuery}
                        />
                        {Platform.OS=='android' ?
                            <Button icon={() => <FontAwesomeIcon icon={ faPlusCircle } />} mode="outlined" onPress={() => {navigation.navigate('AddUserCategory')}}>Add Category</Button>
                            :
                            <Link to="/addusercategory"><Button mode="outlined" icon={() => <FontAwesomeIcon icon={ faPlusCircle } />}>Add Category</Button></Link>
                        }
                        {userCategory ?
                            userCategory.map((item)=>{
                                if(item.category_name.toUpperCase().search(searchQuery.toUpperCase())!=-1){
                                        return (
                                            <Menu.Item title={item.category_name} onPress={()=>chooseCategory(item._id, item.category_name)} />
                                        )
                                    }
                            })
                            :
                            <Menu.Item title="No User Category Available" />
                        }
                    </Menu>
                    {(category=="National Sales") ?
                    <Menu
                    visible={visible3}
                    onDismiss={closeMenu3}
                    anchor={<Button style={styles.input} mode="outlined"  onPress={openMenu3}>{national_email} </Button>}>
                        {national?
                            national.map((item)=>{
                                return (
                                    <Menu.Item title={item.nick_name} onPress={()=>chooseNational(item._id, item.email)} />
                                )
                            })
                            :
                            <Menu.Item title="No National sales Available" />
                        }
                    </Menu>
                    : null
                    }
                    {(category=="Regional Sales") ?
                    <Menu
                    visible={visible3}
                    onDismiss={closeMenu3}
                    anchor={<Button style={styles.input} mode="outlined"  onPress={openMenu3}>{regional_email} </Button>}>
                        {regional?
                            regional.map((item)=>{
                                return (
                                    <Menu.Item title={item.nick_name} onPress={()=>chooseregional(item._id, item.email)} />
                                )
                            })
                            :
                            <Menu.Item title="No Regional sales Available" />
                        }
                    </Menu>
                    : null
                    }
                    {(category=="District Sales") ?
                    <Menu
                    visible={visible3}
                    onDismiss={closeMenu3}
                    anchor={<Button style={styles.input} mode="outlined"  onPress={openMenu3}>{district_email} </Button>}>
                        {district?
                            district.map((item)=>{
                                return (
                                    <Menu.Item title={item.nick_name} onPress={()=>chooseDistrict(item._id, item.email)} />
                                )
                            })
                            :
                            <Menu.Item title="No District sales Available" />
                        }
                    </Menu>
                    : null
                    }
                    <Searchbar
                        icon={() => <FontAwesomeIcon icon={ faSearch } />}
                        clearIcon={() => <FontAwesomeIcon icon={ faTimes } />}
                        placeholder="Search"
                        onChangeText={onChangeSearch}
		                value={searchQuery}
                    />
                    <DataTable.Header>
                        <DataTable.Title onPress={()=>sorting("selected")}><FontAwesomeIcon icon={ faSort } />Selected</DataTable.Title>
                        <DataTable.Title onPress={()=>sorting("name")}><FontAwesomeIcon icon={ faSort } /> Target </DataTable.Title>
                        <DataTable.Title onPress={()=>sorting("name")}><FontAwesomeIcon icon={ faSort } /> Sales Completed</DataTable.Title>
                    </DataTable.Header>

                    {category=="National Sales" && allOrders && national_email?
                        allOrders.map((item, index)=>{
                            if(national_email==item.national_email){
                                // console.log(item.value);
                            var re;
                            var ta;
                            item.value.map(it=>{
                                re=it.region;
                                ta=it.target;
                            })
                            return (
                                <DataTable.Row>
                                    <DataTable.Cell>{re}</DataTable.Cell>
                                    <DataTable.Cell>{ta}</DataTable.Cell>
                                    <DataTable.Cell>{}</DataTable.Cell>
                                </DataTable.Row>
                            ) 
                             
                        }})
                        :null
                    }
                    {category=="Regional Sales" && allOrders && regional_email?
                        allOrders.map((item, index)=>{
                        if(regional_email==item.regional_email){
                        var dist;
                        var tar;
                        item.value1.map(it=>{
                            dist=it.dist;
                            tar=it.target1;
                        })
                        return (
                            <DataTable.Row>
                                <DataTable.Cell>{dist}</DataTable.Cell>
                                <DataTable.Cell>{tar}</DataTable.Cell>
                                <DataTable.Cell>{}</DataTable.Cell>
                            </DataTable.Row>
                        )
                        }})
                        :null
                    }
                    {category=="District Sales" && allOrders && district_email?
                        allOrders.map((item,index)=>{
                            if(district_email==item.district_email){
                                var distri;
                                var target;
                                var targetPrice=0;
                        {item.value.map(it=>{
                            distri=it.distri
                            target=it.target2
                        })}
                        {allUpdateDelivery.map(val=>{
                            val.order[0].items.map(items=>{
                            targetPrice=items.targetPrice;
                        })}
                        )}
                        return (
                        <DataTable.Row>
                        <DataTable.Cell>{distri}</DataTable.Cell>
                        <DataTable.Cell>{target}</DataTable.Cell>
                        <DataTable.Cell>{targetPrice}</DataTable.Cell>
                        </DataTable.Row>
                        )
                        }})
                        :null
                    }
                </DataTable>
            </View>
        </ScrollView>
        </SafeAreaView>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        margin: '2%',
        alignSelf: 'center',
        ...Platform.select({
            ios: {
                
            },
            android: {
                width: '100%',
            },
            default: {
                width: '20%',
            }
        })
    },
    title: {
        ...Platform.select({
            ios: {
                
            },
            android: {
                textAlign: 'center',
                color: 'green',
                fontFamily: 'Roboto'
            },
            default: {
                textAlign: 'center',
                color: 'green',
                fontSize: 28,
                fontFamily: 'Roboto'
            }
        })
    },
    datatable: {
        alignSelf: 'center',
        marginTop: '2%',
        marginBottom: '2%',
        padding: '2%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                width: '100%',
            },
            default: {
                width: '75%',
                border: '1px solid gray',
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
            }
        })
    },
}); 
import React, {useState,useEffect} from 'react';
import { View, StyleSheet, Platform, ScrollView, SafeAreaView, TouchableOpacity, Image } from 'react-native';
import { TextInput, Card, Provider, DefaultTheme, DataTable, Title, Button, Portal, Modal } from 'react-native-paper';
import { useHistory } from 'react-router-dom';
import { Delivered_order_by_id } from '../../../services/report/delivered';
import { saveAs } from 'file-saver';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#0cc261',
        accent: '#f1c40f',
    },
};

export default function ViewOrderDelivery(props,{route}) {

    let history = useHistory();

    var orderid = "";
    if(Platform.OS=="android"){
        orderid = route.params.id;
    }
    else{
        orderid = props.match.params.id;
    }
    
    const [order, setOrder] = useState();
    const [acceptedItems, setAcceptedItems] = useState();
    const [img, setImg] = useState();

    useEffect(() => {

        if(orderid){
            Delivered_order_by_id(orderid)
            .then(result=> {
                setOrder(result[0].order);
                setAcceptedItems(result[0].accepted_items);
                setImg(result[0].receipt)
            })
        }

    }, [orderid, order]);

    function goBack(){
        history.goBack();
    }

    const download = url => {
        const img_type = url.split(".")[1];
        saveAs(url, 'image.'+img_type);
    };

    var htmlContent ;
    if(order && acceptedItems){
        htmlContent=`
        <html>

        <head>
            <meta charset="UTF-8">
            <title>Invoice</title>
            <style>
                body {
                    margin: 0;
                    padding: 0;
                    font-size: 16px;
                    font-weight: 300;
                    width: 40%;
                    background: rgba(204, 204, 204, 0);
                    font-family: 'Roboto Condensed', sans-serif;
                }
        
                h2,
                h4,
                p {
                    margin: 0;
                }
        
                h6 {
                    padding: 2px;
                    margin-left: 78px;
                }
        
                .page {
                    background: #fff;
                    display: block;
                    margin: 1rem auto 1rem auto;
                    position: relative;
                    box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
        
                }
        
                .page[size="A4"] {
                    width: 21cm;
                    height: 29.7cm;
                    overflow-x: hidden;
                }
        
                .pdf-title {
                    padding: 0px;
                    font-size: 16px;
                    text-align: right;
                    margin-left: 370px;
                    margin-top: -120px;
                }
        
                /* Top Section */
                .top-section {
                    color: rgb(5, 5, 5);
                    padding: 16px;
                    height: 118px;
                    width: 780px;
                }
        
                .top-section h2 {
                    font-size: 35px;
                    padding: 8px;
                    margin-bottom: 4px;
                    font-weight: 400;
                }
        
                .top-section .logo_sec {
                    display: flex;
                }
        
                .top-section .logo {
                    align-items: center;
                }
        
                .top-section .address {
                    width: 50%;
                    height: 100%;
                    float: center;
                    margin-left: 70px;
                }
        
                .top-section .address-content {
                    max-width: 333px;
                    padding: 0px;
                    font-size: 10px;
                    text-align: center;
                    margin-left: 9px;
                }
        
                /*Billed_to*/
                .billed_to {
                    padding: 0 20px;
                }
        
                .billed_to table {
                    width: 85%;
                    margin-left: 55px;
                    margin-top: 122px;
                }
        
                .billed_to table,
                td {
                    padding: 9px;
                    text-align: center;
                    font-size: 12px;
                    border: 1px solid#616161;
                    border-collapse: collapse;
                }
        
                .billed_to table,
                tr th {
                    border: 1px solid#616161;
                    border-collapse: collapse;
                    text-align: center;
                    padding: 4px;
                }
        
                /*Item shipped table*/
                .item_table {
                    padding: 0px;
                    display: flex;
                }
        
                .item_table table {
                    width: 89%;
                    margin-left: 40px;
                    margin-top: 127px;
                }
        
                .item_table table,
                td {
                    padding: 6px;
                    text-align: center;
                    font-size: 12px;
                    border: 1px solid#616161;
                    border-collapse: collapse;
                }
        
                .item_table table,
                tr th {
                    border: 1px solid#616161;
                    border-collapse: collapse;
                    text-align: center;
                    margin-left: 30px;
                }
            </style>
        </head>
        
        <body>
            <div class="page" size="A4">
                <div class="top-section">
                    <div class="logo_sec">
                        <div class="logo">
                            <img src="/images/Asthara-Logo.png"
                                style="width:65% ;height:initial">
                        </div>
                        <div class="address">
                            <div class="address-content">
                                <h2> Asthara-Agro </h2>
                                <p> Transposeon Pvt Ltd, No 402, Kundalahalli Grama Main Road, B Block,
                                    Bhadra Brook Fields Apts, Bangalore, Karnataka, India - 560037 </p>
                            </div>
                            <div class="pdf-title">
                                <h6>delivered order</h6>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="height: 2px;background:rgb(0, 0, 0);">
                <div class="billed_to">
                    <table>
                        <tr>
                            <th> Full_Name </th>
                            <th> Email</th>
                            <th> Address</th>
                        </tr>
                        <tr>
                            <td> ${order[0].name} </td>
                            <td> ${order[0].email} </td>
                            <td> ${order[0].address} , ${order[0].landmark} ,${order[0].district},
                                ${order[0].state} ,${order[0].country} ,${order[0].postal_code}
                            </td>
                        </tr>
        
                    </table>
                </div>
                <div class="item_table">
                    <table>
                        <tr>
                            <th>Sr No.</th>
                            <th>Item Name</th>
                            <th>Unit</th>
                            <th>Quantity</th>
                            <th>Accepted</th>
                            <th>Rejected</th>
                            <th>Final Price</th>
                            <th>Total Price</th>
                            <th>CGST Tax Rate</th>
                            <th>SGST Tax Rate</th>
                            <th>CGST Tax Amount</th>
                            <th>SGST Tax Amount</th>
                            <th>Total Amount</th>
                        </tr>
        
    `;
        }
        
        else{
            htmlContent=`<h1>No data Available</h1>`;
        }

    function goBack(){
        history.push('/allorderdeliveries');
    }

    function createPDF(){
        noOfItems();
        var printContents = htmlContent;
        var originalContents = document.body.innerHTML;
        document.body.innerHTML = printContents;
        html2canvas(document.body).then(canvas => {
            document.body.appendChild(canvas);
            let base64image = canvas.toDataURL('image/png');
            const doc = new jsPDF({
				unit: "mm",
				format: [265, 350]
			});
            doc.addImage(base64image, 'PNG', 0, 0);
            doc.save("delivered_order.pdf");
            document.body.innerHTML = originalContents;
        });
    }

    const noOfItems=() => {
        var i;
        var sum=0;
        for(i=0;i<Object.keys(order[0].items).length;i++){   
                       //loop for retrieving data from the database
            var total=order[0].items[i].itemNegotiatePrice*acceptedItems[i].quantity;
            var tax=total*0.09;
            htmlContent+=`<tr>`;
            htmlContent+=`<td> ${i+1} `;
            htmlContent+=`<td> ${order[0].items[i].itemName} </td>`;
            htmlContent+=`<td> ${order[0].items[i].itemUnit}</td>`;
            htmlContent+=`<td> ${order[0].items[i].quantity}</td>`;
            htmlContent+=`<td> ${acceptedItems[i].quantity}</td>`;
            htmlContent+=`<td> ${order[0].items[i].quantity-acceptedItems[i].quantity}</td>`;
            htmlContent+=`<td> ${order[0].items[i].itemNegotiatePrice}</td>`;
            htmlContent+=`<td> ${order[0].items[i].itemNegotiatePrice*acceptedItems[i].quantity}</td>`;
            htmlContent+=`<td>9%</td>`
            htmlContent+=`<td>9%</td>`
            htmlContent+=`<td>${tax}</td>`
            htmlContent+=`<td>${tax}</td>`
            htmlContent+=`<td>${total+tax+tax}</td>`
            htmlContent+=`</tr>`;
            sum+=total+tax+tax;
        }
        htmlContent+=`<tr>`
        htmlContent+=`<th  style="text-align:right" colspan="12">Total :</th>`
            htmlContent+=`<td> ${sum}</td>`
    htmlContent+=`</tr>`
            htmlContent+=`</table>
            </div>
        </div>
    </body>
    </html>
    `;
    }

    return (
        <Provider theme={theme}>
            <SafeAreaView>
            <ScrollView>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Card style={styles.card}>
                    <View style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                        <Card.Title style={{ flex: 1,}} title="View Order Delivery Details"/>
                        <TouchableOpacity style={{marginTop : '2%', padding: '1%'}} onPress={() => createPDF()}><FontAwesomeIcon icon={ faFilePdf } color="red" size="35" /></TouchableOpacity>
                        <Button mode="contained" style={styles.button} onPress={()=>goBack()}>Go Back</Button>
                    </View>
                    <Card.Content>
                    {order &&
                        <>
                            <TextInput style={styles.input} mode="outlined" label="Full Name" value={order[0].name} />
                            <TextInput style={styles.input} mode="outlined" label="Email" value={order[0].email} />
                            <TextInput style={styles.input} mode="outlined" label="Mobile no" value={order[0].mobile_no} />
                            <TextInput style={styles.input} mode="outlined" label="Address" value={order[0].address} multiline rows={5} />
                            <TextInput style={styles.input} mode="outlined" label="Landmark" value={order[0].landmark} />
                            <TextInput style={styles.input} mode="outlined" label="District" value={order[0].district} />
                            <TextInput style={styles.input} mode="outlined" label="State" value={order[0].state} />
                            <TextInput style={styles.input} mode="outlined" label="Country" value={order[0].country} />
                            <TextInput style={styles.input} mode="outlined" label="Pin Code" value={order[0].postal_code} />
                            {order[0].items && 
                                <DataTable>
                                    <Title style={{marginTop: '20px', marginBottom: '20px'}}>All Items</Title>
                                    <DataTable.Header>
                                        <DataTable.Title>Item Name</DataTable.Title>
                                        <DataTable.Title>unit</DataTable.Title>
                                        <DataTable.Title>Quantity</DataTable.Title>
                                        <DataTable.Title>Accepted</DataTable.Title>
                                        <DataTable.Title>Rejected</DataTable.Title>
                                        <DataTable.Title>Final Price</DataTable.Title>
                                        <DataTable.Title>Negotiate Price</DataTable.Title>
                                    </DataTable.Header>
                                    {acceptedItems && order[0].items.map((it, index) => {
                                        console.log(acceptedItems);
                                        return (
                                            <>
                                                <DataTable.Row>
                                                    <DataTable.Cell>{it.itemName}</DataTable.Cell>
                                                    <DataTable.Cell>{it.itemUnit}</DataTable.Cell>
                                                    <DataTable.Cell>{it.quantity}</DataTable.Cell>
                                                    <DataTable.Cell>{acceptedItems[index].quantity}</DataTable.Cell>
                                                    <DataTable.Cell>{it.quantity-acceptedItems[index].quantity}</DataTable.Cell>
                                                    <DataTable.Cell>{it.targetPrice}</DataTable.Cell>
                                                    <DataTable.Cell>{it.itemNegotiatePrice}</DataTable.Cell>
                                                </DataTable.Row>
                                            </>
                                        )
                                    })}
                                </DataTable>
                            }
                            {img ?
                                <Image
                                    style={{width: 400, height: 500, border: '1px solid black'}}
                                    source={img}
                                    onClick={() => download(img)}
                                />
                                :
                                null
                            }
                        </>
                    }
                    </Card.Content>
                </Card>
            </View>
            </ScrollView>
            </SafeAreaView>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        alignSelf: 'center',
        padding: '1%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                marginTop: '10%',
                marginBottom: '10%',
                width: '90%',
            },
            default: {
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
                marginTop: '4%',
                marginBottom: '4%',
                width: '75%',
            }
        })
    },
    input: {
        marginTop: '2%',
        width: '100%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                
            }
        })
    },
    button: {
        marginTop: '2%',
        flex: 1,
    },
}); 

import React, {useState, useEffect} from 'react';
import { View, StyleSheet, Platform, ActivityIndicator, ScrollView, SafeAreaView } from 'react-native';
import { Provider, DefaultTheme, Button, Title, DataTable, Searchbar, Text } from 'react-native-paper';
import { Link } from "react-router-dom";
import { CSVLink} from "react-csv";
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faSearch, faTimes, faEye, faSort,faFileCsv } from '@fortawesome/free-solid-svg-icons';
import { allOrder } from '../../services/order_api';
import { manager_pool_by_id } from '../../services/pool';
import { users_by_id } from '../../services/user_api';
import { roleas, loginuserId } from '../../utils/user';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#0cc261',
        accent: '#f1c40f',
    },
};

export default function AllOrders({ navigation }, props) {

    const [searchQuery, setSearchQuery] = useState('');
    const [allOrders, setAllOrders] = useState([]);
    const [managerPoolId, setManagerPoolId] = useState('');
    const [managerPinCodes, setManagerPinCodes] = useState('');
    const [role, setRole] = useState('');
    const [userId, setUserId] = useState('');
    const [sorting_order, setSortingOrder] = useState('ASC');

    useEffect(() => {
        
        if(role && role=='manager' && userId){
            users_by_id(userId)
            .then(result=>{
                setManagerPoolId(result[0].pool_id);
            })
        }

        if(managerPoolId){
            manager_pool_by_id(managerPoolId)
            .then(result=>{
                setManagerPinCodes(result[0].postal_code);
            })
        }

        allOrder()
        .then(result=> {
            setAllOrders(result);
        })

        roleas()  
        .then(result => {
            setRole(result);
        })

        loginuserId()  
        .then(result => {
            setUserId(result);
        })

    }, [managerPoolId, role, userId]);

    const sorting = (col)=>{
        if(sorting_order=="ASC"){
            const sorted=([...allOrders].sort((a,b)=>
            a[col].toLowerCase()>b[col].toLowerCase() ?1:-1));
            setAllOrders(sorted);
            setSortingOrder('DES');
        }
        if(sorting_order=="DES"){
            const sorted=([...allOrders].sort((a,b)=>
            a[col].toLowerCase()<b[col].toLowerCase() ?1:-1));
            setAllOrders(sorted);
            setSortingOrder('ASC');
        }
    }
    const [fileHeaders] = useState([
        {label: 'Order ID', key: 'custId'},
        {label: 'Order Date', key: 'order_date'},
        {label: 'Email', key: 'email'},
        {label: 'Name', key: 'name'},
        {label: 'Mobile No', key: 'mobile_no'},
        {label: 'Address', key: 'address'},
        {label: 'Landmark', key: 'landmark'},
        {label: 'District', key: 'district'},
        {label: 'State', key: 'state'},
        {label: 'Country', key: 'country'},
        {label: 'PinCode', key: 'postal_code'},
        {label: 'Incentive', key: 'incentive'},
        {label: 'Status', key: 'status'},

      ]
      );
    
      let data = []
      allOrders.forEach(item => {
        var date=item.order_date.substring(0,10);
            var d=new Date(item.order_date);
            d.toTimeString();
            d=String(d);
            var hour=d.substring(16,18);
            var custId=item.nick_name+"_"+item.postal_code+"_"+date+"_"+hour;
            var incentive=0.0;
            item.items.map((it)=>{
                incentive+=(parseInt(it.itemNegotiatePrice)-it.targetPrice)*it.quantity*0.1;
            })
           incentive= incentive.toFixed(2)
          data.push({
              custId:custId,
              order_date:date,
              name: item.name,
              email: item.email,
              mobile_no: item.mobile_no,
              address:item.address,
              landmark:item.landmark,
              district:item.district,
              state:item.state,
              country:item.country,
              postal_code:item.postal_code,
              status:item.status,
              incentive:incentive,
          });
          
      });
    const onChangeSearch = query => setSearchQuery(query);

    return (
        <Provider theme={theme}>
        <SafeAreaView>
        <ScrollView>
            <View>
                <DataTable style={styles.datatable}>
                <View style={{flexDirection: 'row', justifyContent: 'space-between'}}>
                    <View style={{ flex: 1, flexDirection: 'row', justifyContent: 'space-between' }}>
                    <Title style={styles.title} >All Orders</Title>   
                    <CSVLink data={data}  headers={fileHeaders} filename={"AllOrders.csv"}><FontAwesomeIcon color="green" size="30" icon={ faFileCsv } /></CSVLink>
                    </View>                   
                </View>
                {/* <Title style={styles.title} >All Orders</Title> */}
                    <Searchbar
                        icon={() => <FontAwesomeIcon icon={ faSearch } />}
                        clearIcon={() => <FontAwesomeIcon icon={ faTimes } />}
                        placeholder="Search"
                        onChangeText={onChangeSearch}
		                value={searchQuery}
                    />
                    <DataTable.Header>
                        <DataTable.Title onPress={()=>sorting("order_date")}><FontAwesomeIcon icon={ faSort } /> Order ID</DataTable.Title>
                        <DataTable.Title onPress={()=>sorting("name")}><FontAwesomeIcon icon={ faSort } /> Customer Name</DataTable.Title>
                        {role== "manager" ?
                            <DataTable.Title ><FontAwesomeIcon icon={ faSort } />Expected Incentive(RS)</DataTable.Title>
                            :
                            null
                        }
                        <DataTable.Title onPress={()=>sorting("status")}><FontAwesomeIcon icon={ faSort } /> Status</DataTable.Title>
                        <DataTable.Title numeric>Action</DataTable.Title>
                    </DataTable.Header>

                    {(role && userId && (role=="sales" || role=="customer") && allOrders) ?
                        allOrders.map((item, index)=>{
                            if(item.userId==userId)
                            if(item.email.toUpperCase().search(searchQuery.toUpperCase())!=-1 || item.name.toUpperCase().search(searchQuery.toUpperCase())!=-1 || item.status.toUpperCase().search(searchQuery.toUpperCase())!=-1){
                                var date=item.order_date.substring(0,10);
                                var d=new Date(item.order_date);
                                d.toTimeString();
                                d=String(d);
                                var hour=d.substring(16,18);
                                var custom_orderId=item.nick_name+"_"+item.postal_code+"_"+date+"_"+hour;
                                return (
                                    <DataTable.Row>
                                        <DataTable.Cell>{custom_orderId}</DataTable.Cell>
                                        <DataTable.Cell>{item.name}</DataTable.Cell>
                                        <DataTable.Cell>{item.status}</DataTable.Cell>
                                        <DataTable.Cell numeric>
                                            {Platform.OS=='android' ?
                                                // <Button  mode="contained" onPress={() => {navigation.navigate('EditOrder', {itemId: item._id})}}>Check</Button>
                                                <Button mode="contained"  icon={() => <FontAwesomeIcon icon={ faEye } />} onPress={() => {navigation.navigate('ExportPdf', {orderId: item._id})}}></Button>

                                                :
                                                <Link to={"/vieworder/"+item._id}><Button mode="contained" icon={() => <FontAwesomeIcon icon={ faEye } />} style={{width: '100%'}}>Details</Button></Link>
                                            }
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                )
                            }
                        })
                        :null
                    }
                    {(role && userId && role=="manager"  && allOrders && managerPinCodes) ?
                        allOrders.map((item, index)=>{
                            if(managerPinCodes.includes(String(item.postal_code)))
                            if(item.email.toUpperCase().search(searchQuery.toUpperCase())!=-1 || item.name.toUpperCase().search(searchQuery.toUpperCase())!=-1 || item.status.toUpperCase().search(searchQuery.toUpperCase())!=-1){
                                var date=item.order_date.substring(0,10);
                                var d=new Date(item.order_date);
                                d.toTimeString();
                                d=String(d);
                                var hour=d.substring(16,18);
                                var custom_orderId=item.nick_name+"_"+item.postal_code+"_"+date+"_"+hour;
                                var incentive=0.0;
                                item.items.map((it, index)=>{
                                    incentive+=(parseInt(it.itemNegotiatePrice)-it.targetPrice)*it.quantity*0.1;
                                })
                                return (
                                    <DataTable.Row>
                                        <DataTable.Cell>{custom_orderId}</DataTable.Cell>
                                        <DataTable.Cell>{item.name}</DataTable.Cell>
                                        <DataTable.Cell>{incentive.toFixed(2)}</DataTable.Cell>
                                        <DataTable.Cell>{item.status}</DataTable.Cell>
                                        <DataTable.Cell numeric>
                                            {Platform.OS=='android' ?
                                                //  <Button mode="contained"  icon={() => <FontAwesomeIcon icon={ faEye } />} onPress={() => {navigation.navigate('ViewOrder', {orderId: item._id})}}></Button>
                                                <Button mode="contained"  icon={() => <FontAwesomeIcon icon={ faEye } />} onPress={() => {navigation.navigate('ExportPdf', {orderId: item._id})}}></Button>
                                                :
                                                <Link to={"/vieworder/"+item._id}><Button mode="contained" icon={() => <FontAwesomeIcon icon={ faEye } />} style={{width: '100%'}}>Details</Button></Link>
                                            }
                                        </DataTable.Cell>
                                    </DataTable.Row>
                                )
                            }
                        })
                        :null
                    }
                </DataTable>
            </View>
        </ScrollView>
        </SafeAreaView>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        margin: '2%',
        alignSelf: 'center',
        ...Platform.select({
            ios: {
                
            },
            android: {
                width: '100%',
            },
            default: {
                width: '20%',
            }
        })
    },
    title: {
        ...Platform.select({
            ios: {
                
            },
            android: {
                textAlign: 'center',
                color: 'green',
                fontFamily: 'Roboto'
            },
            default: {
                textAlign: 'center',
                color: 'green',
                fontSize: 28,
                fontFamily: 'Roboto'
            }
        })
    },
    datatable: {
        alignSelf: 'center',
        marginTop: '2%',
        marginBottom: '2%',
        padding: '2%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                width: '100%',
            },
            default: {
                width: '75%',
                border: '1px solid gray',
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
            }
        })
    },
}); 
import {url} from '../utils/url';
import axios from 'axios';
//retrive all items
export const allScrapOrder = () => {
    return axios.get(url + '/retrive_all_scrap_order')
    .then(res => {
        return res.data;
    }).catch(err => console.log(err))
}

//retrive all order by id 
export const Scrap_Order_by_id = (id) => {
    return axios.get(url + '/retrive_scrap_order/'+ id)
    .then(res => {
        return res.data;
    }).catch(err => console.log(err))
}

import {url} from '../utils/url';
import { loginuserId } from '../utils/user';

let userId;

loginuserId()  
.then(result => {
    userId=result;
})

//upload image
export function uploadImage(file){
    const data = new FormData();
    // Update the formData object
    const date = new Date();
    var datestring = date.getDate()+"-"+(date.getMonth()+1)+"-"+date.getFullYear()+"-"+date.getHours()+"-"+ date.getMinutes()+"-"+ date.getSeconds()+"-"+userId;
    var newFileName = datestring+file.name;

    data.append(
        "file",
        file,
        newFileName
    );

    // return fetch(`${url}/uploadfile`, {
    //     method: 'POST',
    //     body:data,
    // })
    // .then(res => res.json())
    // .catch(error => console.log(error))
    // .then(Image => {
    //     return Image.img;
    // });

    fetch(`${url}/upload`, {
        method: 'POST',
        body:data,
    })
    .then(Image => {
        console.log(Image);
    });
    return newFileName;
}

export function getImage(file){
    return fetch(`${url}/get_file/`+file, {
        method: 'GET'
    })
    .then(res => res.json())
    .then(Image => {
        return Image;
    })
    .catch(error => console.log(error));
}
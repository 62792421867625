import React, {useState,useEffect} from 'react';
import { View, StyleSheet, Platform, ScrollView, SafeAreaView, CheckBox, Text } from 'react-native';
import { FontAwesomeIcon } from '@fortawesome/react-native-fontawesome';
import { faPlusCircle,faMinusCircle, faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { TextInput, Card, Button, Menu, Provider, DefaultTheme, Searchbar } from 'react-native-paper';
import  {all_users_by_role, user_category, user_address} from '../../services/user_api';
import {Link, useHistory } from "react-router-dom";
import {url} from '../../utils/url';
import axios from 'axios';
import { loginuserId } from '../../utils/user';

const theme = {
    ...DefaultTheme,
    roundness: 2,
    colors: {
        ...DefaultTheme.colors,
        primary: '#0cc261',
        accent: '#f1c40f',
    },
};

export default function AssignSales({ navigation },props) {

    const [visible, setVisible] = useState([]);
    const [visible1, setVisible1] = useState(false);
    const [visible3, setVisible3] = useState(false)
    const [visible4, setVisible4] = useState(false)
    const [visible5, setVisible5] = useState(false)
    const [national, setNational] = useState();
    const [regional, setRegional] = useState();
    const [isRegional, setIsRegional] = useState([]);
    const [checkedv, setChecked]=useState([])
    const [checkedv1, setChecked1]=useState([])
    const [checkedv2, setChecked2]=useState([])
    const [isDistrict, setIsDistrict] = useState([]);
    const [isDistributor, setIsDistributor] = useState([]);
    const [district, setDistrict] = useState();
    const [distributor, setDistributor] = useState();
    const [national_email, setNationalEmail] = useState("Choose National Sales");
    const [regional_email, setRegionalEmail] = useState("Choose Regional Sales");
    const [district_email, setDistrictEmail] = useState("Choose District Sales");
    const [distributor_email, setDistributorEmail] = useState("Choose Distributors");
    const [regional_id, setRegionalId] = useState();
    const [national_id, setNationalId] = useState();
    const [district_id, setDistrictId] = useState();
    const [distributor_id, setDistributorId] = useState();
    const [rPinCode, setRpinCode] = useState([]);
    const [userId, setUserId] = useState();
    const [userCategory, setUserCategory] = useState();
    const [category, setCategory] = useState("Choose Category");
    const [categoryId, setCategoryId] = useState("");
    const [roleas, setRoleas] = useState("");
    const [searchQuery, setSearchQuery] = useState('');
    const [flag, setFlag] = useState(true);
    const [flag2, setFlag2] = useState(true);
    const [flag3, setFlag3] = useState(true);
    const [target, setTarget] = useState([]);
    const [target1, setTarget1] = useState([]);
    const [target2, setTarget2] = useState([]);


    // const [pair,setPair]= useState({reg:'', tar:''})

    let history = useHistory();

    useEffect(() => {
        setRoleas(props.roleas);

        user_category()
        .then(function(result) {
            setUserCategory(result);
        });

        all_users_by_role("National Sales")
        .then(result => {
            setNational(result);
        })

        all_users_by_role("Regional Sales")
        .then(result => {
            setRegional(result);
        })

        all_users_by_role("District Sales")
        .then(result => {
            setDistrict(result);
        })

        all_users_by_role("sales")
        .then(result => {
            setDistributor(result);
        })

        loginuserId()  
        .then(result => {
            setUserId(result);
        })

        user_address(regional_id)
        .then(result=>{
            console.log(result[0].postal_code);
            setRpinCode(result.postal_code);
        })

    }, [userId, userCategory, props.roleas, regional_id]);

    // setPair({...checkedv, ...target})
    const openMenu1 = () => setVisible1(true);
    const closeMenu1 = () => setVisible1(false);

    const openMenu3 = () => setVisible3(true);
    const closeMenu3 = () => setVisible3(false);

    const openMenu4 = () => setVisible4(true);
    const closeMenu4 = () => setVisible4(false);

    const openMenu5 = () => setVisible5(true);
    const closeMenu5 = () => setVisible5(false);
    function chooseCategory(id, name) {
        setCategoryId(id);
        setCategory(name);
        closeMenu1();
    }
    if(flag && regional){
        const values = [...isRegional];
        for(let i=0; i<regional.length; i++) {
            values.push(true);  
        }
        setIsRegional(values);
        setFlag(false);
    }

    if(flag2 && district){
        const values = [...isDistrict];
       
        for(let i=0; i<district.length; i++) {
            values.push(true);  
        }
        setIsDistrict(values);
        setFlag2(false);
    }

    if(flag3 && distributor){
        const values = [...isDistributor];
       
        for(let i=0; i<distributor.length; i++) {
            values.push(true);  
        }
        setIsDistributor(values);
        setFlag3(false);
    }
    var value=[];
    var check1=[...checkedv];
    var tar1=[...target];
    for(let i=0;i<check1.length && i<tar1.length;i++){
    value.push({region:checkedv[i],
        target:target[i]})
    }; 
    var value1=[]
    var check2=[...checkedv1];
    var tar2=[...target1];
    for(let j=0;j<check2.length && j<tar2.length;j++){
    value1.push({dist:checkedv1[j],
        target1:target1[j]
    }); 
    }
    var value2=[];
    var check3=[...checkedv2];
    var tar3=[...target2]
    for(let k=0;k<check3.length && k<tar3.length;k++){
    value2.push({distri:checkedv2[k],
        target2:target2[k]
    }); 
    }
    function submitForm() {
        console.log("submit");
        const data= {
            category: category,
            national_email: national_email,
            regional_email: regional_email,
            district_email: district_email,
            managerId: userId,
            value: value,
            value1: value1,
            value2: value2,
        }
        axios.post(url + '/assign_sales', data)
          .then(function (response) {
            alert(response.data.message);
            console.log(response.data);
            // if(response.data)
            // {
            //     if(Platform.OS=="android"){
            //         navigation.navigate('');

            //     }
            //     else{
            //         history.push('/');
            //     }
            // }
            }) 
    }
// console.log(checkedv2);
    function chooseNational(id,email){
        setNationalId(id);
        setNationalEmail(email);
        closeMenu3();
    }

    function chooseregional(id, email){
        setRegionalId(id);
        setRegionalEmail(email);
        closeMenu3();
    }

    function chooseDistrict(id,email){
        setDistrictEmail(email);
        closeMenu4();
    }

    function chooseDistributor(email){
        setDistributorEmail(email);
        closeMenu5();
    }

    const onChangeSearch = query => setSearchQuery(query);
    function changeRegionalCheck(index){
        const values = [...isRegional];
        const checkedvalues=[];
        values[index]=!values[index];
        setIsRegional(values);
        for(let i=0; i<regional.length; i++) {
            if(values[i]===true){
                checkedvalues.push(regional[i].nick_name);
            }  
        }
        setChecked(checkedvalues);
    }
    function changeDistrictCheck(index){
        const values = [...isDistrict];
        const checkedvalues=[];
        values[index]=!values[index];
        setIsDistrict(values);
        for(let i=0; i<district.length; i++) {
            if(values[i]===true){
                checkedvalues.push(district[i].nick_name);
            }  
        }
        setChecked1(checkedvalues);
    }

    function changeDistributorCheck(index){
        const values = [...isDistributor];
        const checkedvalues=[];
        values[index]=!values[index];
        setIsDistributor(values);
        for(let i=0; i<distributor.length; i++) {
            if(values[i]===true){
                checkedvalues.push(distributor[i].nick_name);
            }  
        }
        setChecked2(checkedvalues);
    }
   
    const ChangeTarget=(val,index)=>{
        let temp = target.map(i=>i);
        temp[index] = val;
        setTarget(temp);
        }
    const ChangeTarget1=(val,index)=>{
        let temp = target.map(i=>i);
        temp[index] = val;
        setTarget1(temp);
    }
    const ChangeTarget2=(val,index)=>{
        let temp = target.map(i=>i);
        temp[index] = val;
        setTarget2(temp);
    }
    // console.log(distributor);

    return (
        <Provider theme={theme}>
            <SafeAreaView>
            <ScrollView>
            <View style={{ flex: 1, alignItems: 'center', justifyContent: 'center' }}>
                <Card style={styles.card}>
                    <Card.Title titleStyle={styles.title} title="Assign Sales"/>
                    <Card.Content>
                    <Menu
                    visible={visible1}
                    onDismiss={closeMenu1}
                    anchor={<Button style={styles.input} mode="outlined" onPress={openMenu1}>{category}</Button>}>
                        <Searchbar
                            icon={() => <FontAwesomeIcon icon={ faSearch } />}
                            clearIcon={() => <FontAwesomeIcon icon={ faTimes } />}
                            placeholder="Search"
                            onChangeText={onChangeSearch}
                            value={searchQuery}
                        />
                        {Platform.OS=='android' ?
                            <Button icon={() => <FontAwesomeIcon icon={ faPlusCircle } />} mode="outlined" onPress={() => {navigation.navigate('AddUserCategory')}}>Add Category</Button>
                            :
                            <Link to="/addusercategory"><Button mode="outlined" icon={() => <FontAwesomeIcon icon={ faPlusCircle } />}>Add Category</Button></Link>
                        }
                        {userCategory ?
                            userCategory.map((item)=>{
                                if(item.category_name.toUpperCase().search(searchQuery.toUpperCase())!=-1){
                                        return (
                                            <Menu.Item title={item.category_name} onPress={()=>chooseCategory(item._id, item.category_name)} />
                                        )
                                    }
                            })
                            :
                            <Menu.Item title="No User Category Available" />
                        }
                    </Menu>
                    {category=="National Sales" ?
                        <View>
                        <Menu
                        visible={visible3}
                        onDismiss={closeMenu3}
                        anchor={<Button style={styles.input} mode="outlined"  onPress={openMenu3}>{national_email} </Button>}>
                            {national?
                                national.map((item)=>{
                                    return (
                                        <Menu.Item title={item.nick_name} onPress={()=>chooseNational(item._id, item.email)} />
                                    )
                                })
                                :
                                <Menu.Item title="No National sales Available" />
                            }
                        </Menu>
                                <Text style={{color: 'gray', fontSize: '20px', fontWeight: 'bold', fontStyle: 'italic', textDecorationLine: 'underline'}}>Regional Sales</Text>
                                <ScrollView style={{height: '150px'}}>
                                {isRegional && regional &&
                                    regional.map((item, index)=>{
                                        return(
                                            <View style={styles.checkboxContainer}>
                                                <CheckBox
                                                    value={isRegional[index]}
                                                    onValueChange={()=>changeRegionalCheck(index)}
                                                    defaultChecked={true}
                                                    style={styles.checkbox}
                                                />
                                                <Text style={styles.label}>{item.nick_name}</Text>
                                                <TextInput keyboardType='numeric' mode="outlined" label="Target" onChangeText={(e)=>ChangeTarget(e,index)}/>
                                                </View>
                                        )
                                    }) 
                                }
                                </ScrollView>
                            </View>: null
                    }
                    {category=="Regional Sales" ?
                    <View>
                    <Menu
                    visible={visible3}
                    onDismiss={closeMenu3}
                    anchor={<Button style={styles.input} mode="outlined"  onPress={openMenu3}>{regional_email} </Button>}>
                        {regional?
                            regional.map((item)=>{
                                return (
                                    <Menu.Item title={item.nick_name} onPress={()=>chooseregional(item._id, item.email)} />
                                )
                            })
                            :
                            <Menu.Item title="No Regional sales Available" />
                        }
                    </Menu>
                            <Text style={{color: 'gray', fontSize: '20px', fontWeight: 'bold', fontStyle: 'italic', textDecorationLine: 'underline'}}>District Sales</Text>
                            <ScrollView style={{height: '150px'}}>
                            {isDistrict && district &&
                                district.map((item, index)=>{
                                    return(
                                        <View style={styles.checkboxContainer}>
                                            <CheckBox
                                                value={isDistrict[index]}
                                                onValueChange={()=>changeDistrictCheck(index)}
                                                style={styles.checkbox}
                                            />
                                            <Text style={styles.label}>{item.nick_name}</Text>
                                            <TextInput keyboardType='numeric' mode="outlined" label="Target" onChangeText={(e)=>ChangeTarget1(e,index)}/>
                                        </View>
                                    )
                                })
                            }
                            </ScrollView>
                        </View>: null
                    }
                    {category=="District Sales" ?
                        <View>
                        <Menu
                        visible={visible3}
                        onDismiss={closeMenu3}
                        anchor={<Button style={styles.input} mode="outlined"  onPress={openMenu3}>{district_email} </Button>}>
                            {district?
                                district.map((item)=>{
                                    return (
                                        <Menu.Item title={item.nick_name} onPress={()=>chooseDistrict(item._id, item.email)} />
                                    )
                                })
                                :
                                <Menu.Item title="No District sales Available" />
                            }
                        </Menu>
                                <Text style={{color: 'gray', fontSize: '20px', fontWeight: 'bold', fontStyle: 'italic', textDecorationLine: 'underline'}}>Distributors</Text>
                                <ScrollView style={{height: '150px'}}>
                                {isDistributor && distributor &&
                                    distributor.map((item, index)=>{
                                        return(
                                            <View style={styles.checkboxContainer}>
                                                <CheckBox
                                                    value={isDistributor[index]}
                                                    onValueChange={()=>changeDistributorCheck(index)}
                                                    style={styles.checkbox}
                                                />
                                                <Text style={styles.label}>{item.nick_name}</Text>
                                                <TextInput keyboardType='numeric' mode="outlined" label="Target" onChangeText={(e)=>ChangeTarget2(e,index)}/>
                                            </View>
                                        )
                                    })
                                }
                                </ScrollView>
                            </View>: null
                    }
                        <Button mode="contained" style={styles.button} onPress={()=>submitForm()} >Assign</Button>
                    </Card.Content>
                </Card>
            </View>
            </ScrollView>
            </SafeAreaView>
        </Provider>
    );
}

const styles = StyleSheet.create({
    card: {
        alignSelf: 'center',
        padding: '1%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                marginTop: '10%',
                marginBottom: '10%',
                width: '90%',
            },
            default: {
                boxShadow: '0 4px 8px 0 gray, 0 6px 20px 0 gray',
                marginTop: '4%',
                marginBottom: '4%',
                width: '75%',
            }
        })
    },
    input: {
        marginTop: '2%',
        width: '100%',
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                
            }
        })
    },
    title: {
        ...Platform.select({
            ios: {
                
            },
            android: {
                textAlign: 'center',
                color: 'green',
                fontFamily: 'Roboto'
            },
            default: {
                textAlign: 'center',
                color: 'green',
                fontSize: 28,
                fontFamily: 'Roboto'
            }
        })
    },
    button: {
        marginTop: '2%',
    },
    customer: {
        ...Platform.select({
            ios: {
                
            },
            android: {
                
            },
            default: {
                flexDirection: 'row',
                justifyContent: 'space-between',
            }
        })
    },
    checkboxContainer: {
        flexDirection: "row",
    },
    checkbox: {
        alignSelf: "center",
    },
    label: {
        margin: 8,
    }
}); 